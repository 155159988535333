<template>
  <div class="variants">
    <v-row>
      <v-col cols="12" xl="9">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">
            Membership Variants
          </h2>

          <v-btn
            v-show="!membership.is_complete"
            class="primary--text mr-5"
            text
            depressed
            :loading="loading"
            :to="{ name: 'memberships', query: { status: 'draft' } }"
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :loading="loading"
            text
            depressed
            @click="saveAndComplete"
          >
            {{ !membership.is_complete ? 'Complete Challenge' : 'Save' }}
            <v-icon> {{ icons.mdiArrowRight }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xl="9">
        <v-row class="program-variant--form">
          <v-col cols="12">
            <v-checkbox
              v-model="form.is_recurring"
              label="Is Recurring"
              hide-details="auto"
              class="d-inline-block"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.description"
              label="Description"
              :error-messages="form.$getError('description')"
              :hide-details="
                !form.description && !form.$getError('description')
              "
              :disabled="form.$busy"
              :maxlength="100"
              outlined
              counter
              flat
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="form.validity_period"
              label="Period"
              :items="periods"
              :error-messages="form.$getError('validity_period')"
              hide-details="auto"
              :disabled="form.$busy"
              item-value="value"
              item-text="name"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.validity"
              v-mask="'####'"
              label="Validity"
              :error-messages="form.$getError('validity')"
              :hide-details="!form.$getError('validity')"
              :disabled="form.$busy"
              outlined
              flat
            />
          </v-col>

          <template v-if="form.is_recurring">
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="form.iap_ios_subscription_id"
                label="iap_ios_subscription_id"
                :error-messages="form.$getError('iap_ios_subscription_id')"
                :hide-details="
                  !form.iap_ios_subscription_id &&
                    !form.$getError('iap_ios_subscription_id')
                "
                :disabled="form.$busy"
                :maxlength="100"
                outlined
                counter
                flat
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                v-model="form.iap_android_base_plan_id"
                label="iap_android_base_plan_id"
                :error-messages="form.$getError('iap_android_base_plan_id')"
                :hide-details="
                  !form.iap_android_base_plan_id &&
                    !form.$getError('iap_android_base_plan_id')
                "
                :disabled="form.$busy"
                :maxlength="100"
                outlined
                counter
                flat
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                v-model="form.iap_android_subscription_id"
                label="iap_android_subscription_id"
                :error-messages="form.$getError('iap_android_subscription_id')"
                :hide-details="
                  !form.iap_android_subscription_id &&
                    !form.$getError('iap_android_subscription_id')
                "
                :disabled="form.$busy"
                :maxlength="100"
                outlined
                counter
                flat
              />
            </v-col>
          </template>

          <template v-else>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="form.sku_us"
                label="US SKU"
                :error-messages="form.$getError('sku_us')"
                :hide-details="!form.sku_us && !form.$getError('sku_us')"
                :disabled="form.$busy"
                :maxlength="100"
                counter
                outlined
                flat
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="form.sku_au"
                label="AU SKU"
                :error-messages="form.$getError('sku_au')"
                :hide-details="!form.sku_au && !form.$getError('sku_au')"
                :disabled="form.$busy"
                :maxlength="100"
                counter
                outlined
                flat
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="form.price_us"
                label="Price in US"
                :error-messages="form.$getError('price_us')"
                :hide-details="!form.sku_us && !form.$getError('price_us')"
                :disabled="form.$busy"
                outlined
                counter
                flat
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="form.price_au"
                label="Price in AU"
                :error-messages="form.$getError('price_au')"
                :hide-details="!form.sku_au && !form.$getError('price_au')"
                :disabled="form.$busy"
                counter
                outlined
                flat
              />
            </v-col>
          </template>

          <v-col class="text-right" cols="12">
            <v-btn
              v-if="form.id"
              class="warning--text"
              :loading="form.$busy"
              text
              @click="updateVariant(form)"
            >
              Update Variant
            </v-btn>
            <v-btn
              v-else
              class="success--text"
              :loading="form.$busy"
              text
              @click="addMembershipVariant(form)"
            >
              Add New Variant
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-data-table
          no-data-text="No variants found"
          single-expand
          class="elevation-2"
          disable-pagination
          disable-sort
          hide-default-footer
          :items="membership.variants"
          :headers="[
            {
              text: 'Variant',
              value: 'variant',
              cellClass: 'primary--text py-5',
            },
            {
              text: 'Description',
              value: 'description',
              cellClass: 'primary--text',
            },
            {
              text: 'Recurring',
              value: 'is_recurring',
              cellClass: 'primary--text text-capitalize',
            },
            { text: 'Details', value: 'data-table-expand', align: 'center' },
            { text: '', value: 'actions', align: 'right' },
          ]"
          show-expand
          :items-per-page="-1"
        >
          <template v-slot:item.variant="{ item }">
            {{ item.validity }}
            {{ item.validity_period_text | validityPlural(item.validity) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title @click="form.$refresh(item)">
                    Edit
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="removeVariant(item)">
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-5">
              <v-row>
                <v-col v-if="item.is_recurring" cols="12" md="3">
                  <v-text-field
                    label="IAP Subscription"
                    :value="item.iap_ios_subscription_id"
                    readonly
                    :append-outer-icon="icons.mdiContentCopy"
                    @click:append-outer="
                      copyClipboard(item.iap_ios_subscription_id)
                    "
                  />
                  <v-text-field
                    label="Android Base Plan"
                    :value="item.iap_android_base_plan_id"
                    readonly
                    :append-outer-icon="icons.mdiContentCopy"
                    @click:append-outer="
                      copyClipboard(item.iap_android_base_plan_id)
                    "
                  />
                  <v-text-field
                    label="IAP Android Subscription"
                    :value="item.iap_android_subscription_id"
                    readonly
                    :append-outer-icon="icons.mdiContentCopy"
                    :hide-details="true"
                    @click:append-outer="
                      copyClipboard(item.iap_android_subscription_id)
                    "
                  />
                </v-col>
                <v-col v-else cols="12" md="5">
                  <v-text-field
                    label="US Stock Keeping Unit"
                    :value="item.sku_us"
                    readonly
                    :append-outer-icon="icons.mdiContentCopy"
                    @click:append-outer="copyClipboard(item.sku_us)"
                  />
                  <v-text-field
                    label="AU Stock Keeping Unit"
                    :value="item.sku_au"
                    readonly
                    :append-outer-icon="icons.mdiContentCopy"
                    :hide-details="true"
                    @click:append-outer="copyClipboard(item.sku_au)"
                  />
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import { mdiArrowRight, mdiContentCopy, mdiDotsVertical } from '@mdi/js'
import Form from '@/utils/form'
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import ConfirmDialog from '@/components/modals/ConfirmDialog'

const variantFormData = {
  id: null,
  is_recurring: false,
  description: '',
  iap_android_subscription_id: '',
  iap_android_base_plan_id: '',
  iap_ios_subscription_id: '',
  active_from: null,
  active_to: null,
  validity: '',
  validity_period: null,
  sku_au: '',
  sku_us: '',
  price_au: '',
  price_us: '',
}

export default {
  name: 'Variants',
  components: {
    ConfirmDialog,
  },
  directives: {
    mask,
  },
  data() {
    return {
      icons: {
        mdiArrowRight,
        mdiContentCopy,
        mdiDotsVertical,
      },
      loading: false,
      periods: [
        { name: 'Month', value: 'M' },
        { name: 'Year', value: 'Y' },
      ],
      form: new Form({ ...variantFormData }),
      activeFromModal: false,
      activeToModal: false,
    }
  },
  computed: {
    ...mapGetters({
      membership: 'memberships/getSelectedMembership',
    }),
  },
  methods: {
    ...mapActions({
      storeMembershipVariant: 'memberships/storeMembershipVariant',
      updateMembershipVariant: 'memberships/updateMembershipVariant',
      deleteMembershipVariant: 'memberships/deleteMembershipVariant',
      updateMembership: 'memberships/updateMembership',
    }),
    copyClipboard(text) {
      navigator.clipboard.writeText(text)
    },
    async removeVariant(variant) {
      const confirm = await this.$refs.confirmDelete.open(
        'Remove Variant',
        'Are you sure you want to remove this membership variant?'
      )
      if (!confirm) return

      try {
        await this.deleteMembershipVariant(variant)
      } catch ({ response }) {
        if (response && response.status === 422) {
          variant.$setErrors(response.data.errors)
        }
      }
    },
    async updateVariant(form) {
      try {
        await this.updateMembershipVariant(form)
        form.$clearErrors()
        form.$refresh({ ...variantFormData })
      } catch ({ response }) {
        if (response && response.status === 422) {
          form.$setErrors(response.data.errors)
        }
      }
    },
    async addMembershipVariant(form) {
      try {
        await this.storeMembershipVariant(form)
        form.$clearErrors()
        form.$refresh({ ...variantFormData })
      } catch (err) {
        if (err.response && err.response.status === 422) {
          form.$setErrors(err.response.data.errors)
        }
      }
    },
    async saveAndComplete() {
      try {
        await this.updateMembership({ ...this.membership, completed: true })
        this.$router.push({
          name: 'memberships',
          query: { status: 'complete' },
        })
      } catch (err) {
        throw err
      }
    },
  },
}
</script>
